import { studentsByEvolveUserName } from 'mock-data/performancereport/allCourses/students/prod';
import { assignmentSummaryV2ByCourseId } from 'mock-data/performancereport/assignmentSummaryV2/prod';
import { gradebookCourseSection } from 'mock-data/performancereport/gradebook/courseSection/prod';
import { handlers as assignmentEngagementreportV2Handler } from 'mock-data/performancereport/assignmentEngagement/reportV2';
import { handlers as studentHomeReportHandler } from 'mock-data/performancereport/studenthome/report';
import { handlers as studentDetailCourseSectionHanlder } from 'mock-data/performancereport/gradebook/studentdetail/courseSection';
import { handlers as studentEngagementReportV2Handler } from 'mock-data/performancereport/studentengagement/reportV2';
import { handlers as courseSectionHandler } from 'mock-data/performancereport/courseSections';
import { handlers as courseSectionHandlerV2 } from 'mock-data/performancereport/v2/courseSections';
import { handlers as courseAggregationHandler } from 'mock-data/performancereport/v2/courseSections/courseware/courseAggregation';
// import { handlers as hesiAssessReviewHandler } from 'mock-data/performancereport/hesi/program/assessreview';
// import { handlers as hesiCohortsHandler } from 'mock-data/performancereport/hesi/dwh/cohort/cohorts';
// import { handlers as hesiShadowCourseHandler } from 'mock-data/performancereport/ps/instructorprofile/shadowcourse';
// import { handlers as shadowhealthCourseAggregateHandler } from 'mock-data/performancereport/shadowhealth/courseAggregates';
import { handlers as shadowHealthRecentExamsHandler } from 'mock-data/performancereport/hesi/recentExams/program';
// import { handlers as shadowHealthProgramsHandler } from 'mock-data/performancereport/hesi/dwh/cohort/programs';
import { handlers as shadowHealthEvolveUserHandler } from 'mock-data/performancereport/shadowhealth/evolveuser';

import { configureHandlers } from 'mocks/helpers';
import { engagementSummaryV2Map } from 'mock-data/performancereport/engagementSummaryV2/prod';
import { userCrosswalk98812 } from 'mock-data/performancereport/eolsUser/98812/type/evolve/crosswalk/prod';
import { userCrosswalk3395138 } from 'mock-data/performancereport/eolsUser/3395138/type/evolve/crosswalk/prod';

export const prodUrl = 'https://eolsapi.elsevier.com/api/performancereport';
const whitelistedITestingUser = 98812;
const whitelistedSkillian26User = 34508205;

export const prodHandler = configureHandlers([
  {
    url: `${prodUrl}/allCourses/students`,
    handler: (req, res, ctx) => {
      const queryParams = req.url.searchParams;
      const evolveUserName = queryParams.get('evolveUserName');
      if (!evolveUserName) {
        return req.passthrough();
      }
      return res(ctx.status(200), ctx.json(studentsByEvolveUserName));
    },
    method: 'get'
  },
  {
    url: `${prodUrl}/assignmentSummaryV2`,
    handler: (req, res, ctx) => {
      const courseSectionId = req.url.searchParams.get('courseSectionId');
      if (!assignmentSummaryV2ByCourseId[courseSectionId]) {
        req.passthrough();
      }

      return res(ctx.status(200), ctx.json(assignmentSummaryV2ByCourseId[courseSectionId]));
    },
    method: 'get'
  },
  {
    url: `${prodUrl}/engagementSummary/reportV2`,
    handler: (req, res, ctx) => {
      const courseSectionId = req.url.searchParams.get('courseSectionId');
      if (!engagementSummaryV2Map[courseSectionId]) {
        req.passthrough();
      }

      return res(ctx.status(200), ctx.json(engagementSummaryV2Map[courseSectionId]));
    },
    method: 'get'
  },
  {
    url: `${prodUrl}/gradebook/courseSection/:courseSectionId`,
    handler: (req, res, ctx) => {
      const { id } = req.params;
      if (typeof id === 'string' && !assignmentSummaryV2ByCourseId[id]) {
        req.passthrough();
      }
      return res(ctx.status(200), ctx.json(gradebookCourseSection));
    },
    method: 'get'
  },
  {
    url: `${prodUrl}/assignmentEngagement/reportV2`,
    handler: assignmentEngagementreportV2Handler.prod,
    method: 'get'
  },
  {
    url: `${prodUrl}/studenthome/report`,
    handler: studentHomeReportHandler.prod,
    method: 'get'
  },
  {
    url: `${prodUrl}/gradebook/studentdetail/courseSection/:courseSectionId`,
    handler: studentDetailCourseSectionHanlder.prod,
    method: 'get'
  },
  {
    url: `${prodUrl}/studentengagement/reportV2`,
    handler: studentEngagementReportV2Handler.prod,
    method: 'get'
  },
  {
    url: `${prodUrl}/courseSections`,
    handler: courseSectionHandler.prod,
    method: 'get'
  },
  {
    url: `${prodUrl}/courseSections/:courseId`,
    handler: courseSectionHandler.prod,
    method: 'get'
  },
  {
    url: `${prodUrl}/eolsUser/${whitelistedITestingUser}/type/evolve/crosswalk`,
    handler: (req, res, ctx) => {
      return res(ctx.status(200), ctx.json(userCrosswalk98812));
    },
    method: 'get'
  },
  {
    url: `${prodUrl}/eolsUser/${whitelistedSkillian26User}/type/evolve/crosswalk`,
    handler: (req, res, ctx) => {
      return res(ctx.status(200), ctx.json(userCrosswalk3395138));
    },
    method: 'get'
  },
  {
    url: `${prodUrl}/v2/courseSections`,
    handler: courseSectionHandlerV2.prod,
    method: 'get'
  },
  {
    url: `${prodUrl}/v2/courseware/courseAggregation`,
    handler: courseAggregationHandler.prod,
    method: 'get'
  },
  {
    url: `${prodUrl}/shadowhealth/evolveuser/:evolveUserName`,
    handler: shadowHealthEvolveUserHandler.prod,
    method: 'get'
  },
  {
    url: `${prodUrl}/hesi/recentExams/program/:programId`,
    handler: shadowHealthRecentExamsHandler.prod,
    method: 'get'
  }
]);

// Note: For future use
// {
//   url: `${prodUrl}/hesi/program/assessreview`,
//   handler: hesiAssessReviewHandler.prod,
//   method: 'get'
// },
// {
//   url: `${prodUrl}/hesi/dwh/cohort/cohorts`,
//   handler: hesiCohortsHandler.prod,
//   method: 'post'
// },
// {
//   url: `${prodUrl}/ps/instructorprofile/shadowcourse/:studentId`,
//   handler: hesiShadowCourseHandler.prod,
//   method: 'get'
// },
// {
//   url: `${prodUrl}/shadowhealth/courseAggregates/:instructorId`,
//   handler: shadowhealthCourseAggregateHandler.prod,
//   method: 'get'
// }
// {
//   url: `${prodUrl}/hesi/recentExams/program/:programId`,
//   handler: shadowHealthRecentExamsHandler.prod,
//   method: 'get'
// }
// {
//   url: `${prodUrl}/hesi/dwh/cohort/programs`,
//   handler: shadowHealthProgramsHandler.prod,
//   method: 'get'
// }
